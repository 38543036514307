import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PressureComponent } from './components/conversions/pressure/pressure.component'
import { TemperatureComponent } from './components/conversions/temperature/temperature.component' 
import { HomeComponent } from './components/home/home.component'
import { AboutComponent } from './components/about/about.component'
import { LengthComponent } from './components/conversions/length/length.component';
import { CoronaComponent } from './components/corona/corona.component';
import { WeatherComponent } from './components/weather/weather.component';
import { ProjectsComponent } from './components/projects/projects.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'corona', component: CoronaComponent},
  { path: 'weather', component: WeatherComponent },
  { path: 'about', component: AboutComponent },
  { path: 'pressure', component: PressureComponent },
  { path: 'temperature', component: TemperatureComponent},
  { path: 'length', component: LengthComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
