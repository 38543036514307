
<mat-drawer-container class="menu-container">
  <mat-drawer #sideMenu [mode]="'over'" class="side-menu">
    <side-menu (menuEvent)="incomingMenuEvent($event)"></side-menu>
  </mat-drawer>

  <mat-drawer-content>
    <!-- main menu bar-->
    <main-menu (mainMenuEvent)="incomingMainMenuEvent($event)" [checkoutCount]="checkoutCount"></main-menu>
    <div *ngIf="showSearch" class="search-bar">
      <form #searchForm="ngForm" appearance="fill" class="search-form" (ngSubmit)="searchProduct()">
        <mat-form-field appearance="fill" class="search-form-field">
           <input matInput type="text" placeholder="Search..." class="search-input" [(ngModel)]="search" name="search">
           <button mat-icon-button matSuffix>
             <mat-icon>search</mat-icon>
           </button>
          
        </mat-form-field>
       
      </form>
      <div class="search-toggle-btn-wrapper">
         <button mat-icon-button color="primary" (click)="toggleSearch()">
        <mat-icon>close</mat-icon>
      </button>
      </div>
     
      
    </div>
    <router-outlet></router-outlet>
    <ng-adsense [adClient]="addClient" [pageLevelAds]="true"></ng-adsense>
  </mat-drawer-content>
</mat-drawer-container>
