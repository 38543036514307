import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit{

  project: string;
  urls: SafeResourceUrl[];
  constructor(private route: ActivatedRoute, private sanatizer: DomSanitizer) { }
  
  private sanitizeUrl(url: string){
    return this.sanatizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit(): void {
    this. urls = [
      this.sanitizeUrl('https://weather-a2152.web.app'), 
      this.sanitizeUrl('https://corona-tracker-9b0e6.web.app'), 
      this.sanitizeUrl('https://price-matcher-a75ca.web.app')];
    this.route.queryParams.subscribe(params => {
      this.project = params['project'];
    });
  }

}
