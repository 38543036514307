import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pressure',
  templateUrl: './pressure.component.html',
  styleUrls: ['./pressure.component.css']
})
export class PressureComponent implements OnInit {
  convertForm: UntypedFormGroup;
  convertMap: Map<String, Map<String, Number>>;
  convertFrom = "";
  convertTo = "";
  quantity = "";
  convertOutput: number = 0;
  units = ['pascal', 'bar', 'psi', 'atm', 'kPa', 'torr'];

  constructor(private fb: UntypedFormBuilder) {
    
   }

   
  convertUnits(formInput){
    var request = this.convertMap.get(formInput.convertFrom);
    var thing = request.get(formInput.convertTo);
    this.convertOutput = formInput.convertInput * thing.valueOf();
    this.convertFrom = formInput.convertFrom;
    this.convertTo = formInput.convertTo;
    this.quantity = formInput.convertInput;
    console.log("output: " + this.convertOutput);
    console.log("convert From: " + this.convertFrom);
    console.log("convert To: " + this.convertTo);
    
  }
  configureConversionMap(){
    this.convertMap = new Map();
    
    var pascal = new Map();
    pascal.set('pascal', 1);
    pascal.set('bar', 0.00001);
    pascal.set('psi', 0.000145);
    pascal.set('atm', 0.0000099);
    pascal.set('kPa', 0.001);
    pascal.set('torr', 7520);
    this.convertMap.set('pascal', pascal);
    
    var bar = new Map();
    bar.set('pascal', 100000);
    bar.set('bar', 1);
    bar.set('psi', 14.5);
    bar.set('atm', 0.987);
    bar.set('kPa', 100);
    bar.set('torr', 751.88)
    this.convertMap.set('bar', bar);

    var psi = new Map();
    psi.set('pascal', 6895);
    psi.set('bar', 0.06895);
    psi.set('psi', 1);
    psi.set('atm', 0.06805);
    psi.set('kPa', 6.895);
    psi.set('torr', 51.813);
    this.convertMap.set('psi', psi);

    var atm = new Map();
    atm.set('pascal', 101330);
    atm.set('bar', 1.013);
    atm.set('psi', 14.7);
    atm.set('atm', 1);
    atm.set('kPa', 101.325);
    atm.set('torr', 760);
    this.convertMap.set('atm', atm);

    var kpa = new Map();
    kpa.set('pascal', 1000);
    kpa.set('bar', 0.01);
    kpa.set('psi', 0.145);
    kpa.set('atm', 0.00987);
    kpa.set('kPa', 1);
    kpa.set('torr', 7.52);
    this.convertMap.set('kpa', kpa);

    var torr = new Map();
    torr.set('pascal', 133);
    torr.set('bar', 0.00133);
    torr.set('psi', 0.0193);
    torr.set('atm', 0.00132);
    torr.set('kPa', 0.133);
    torr.set('tor', 1);
    this.convertMap.set('torr', torr);
    
  }

  ngOnInit() {
    this.configureConversionMap();
    console.log(this.convertMap);
    this.convertForm = this.fb.group({
      convertFrom: [''],
      convertTo: [''],
      convertInput: [0]
    });
  }

}
