<div [ngSwitch]="project" class="component-container">
    <ng-container *ngSwitchCase="'covid-tracker'">
        <p>covid tracker</p>
        <iframe [src]="urls[1]" class="full-screen-iframe"></iframe>
    </ng-container>
    <ng-container *ngSwitchCase="'weather'">
        <p>weather</p>
        <iframe [src]="urls[0]" class="full-screen-iframe"></iframe>
    </ng-container>
    <ng-container *ngSwitchCase="'price-matcher'">
        <p>price matcher</p>
        <iframe [src]="urls[2]" class="full-screen-iframe"></iframe>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <p>Nothing</p>
    </ng-container>

</div>