export class WeatherRest {
    private HourlyDewPointTemperature;
    private HourlyStationPressure;
    private HourlyRelativeHumidity;
    private HourlyDryBulbTemperature;

   
    public 
    public get dewPoint() : string {
        return this.HourlyDewPointTemperature;
    }
    public set dewPoint(dewPoint : string) {
        this.HourlyDewPointTemperature = dewPoint;
    }
    
    public get pressure() : string {
        return this.HourlyStationPressure;
    }
    
    public set pressure(pressure : string) {
        this.HourlyStationPressure = pressure;
    }
    
    
    public get relativeHumidity() : string {
        return this.HourlyRelativeHumidity;
    }
    
    public set relativeHumidity(rHumidity : string) {
        this.HourlyRelativeHumidity = rHumidity;
    }

    
    public get temperature() : string {
        return this.HourlyDryBulbTemperature;
    }

    
    public set temperature(temperature : string) {
        this.HourlyDryBulbTemperature = temperature;
    }
    
    
    
    
    
    
    
}
