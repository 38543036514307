import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'length',
  templateUrl: './length.component.html',
  styleUrls: ['./length.component.css']
})
export class LengthComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder) { }
  convertForm: UntypedFormGroup;
  convertMap: Map<String, Map<String, Number>>;
  convertFrom = "";
  convertTo = "";
  quantity = "";
  convertOutput: number = 0;
  units = ['meter', 'inch', 'foot', 'kilometer', 'millimeter', 'centimeter', 'yard', 'mile'];

  configureConversionMap(){
    this.convertMap = new Map();
    
    var meter = new Map();
    meter.set('meter', 1);
    meter.set('inch', 39.37008);
    meter.set('foot', 3.28084);
    meter.set('kilometer', .001);
    meter.set('millimeter', 1000);
    meter.set('centimeter', 100);
    meter.set('yard', 1.093613);
    meter.set('mile',.000621371);
    this.convertMap.set('meter', meter);

    var inch = new Map();
    inch.set('meter', .0254);
    inch.set('inch', 1);
    inch.set('foot', .083333);
    inch.set('kilometer', .000254);
    inch.set('millimeter', 25.4);
    inch.set('centimeter', 2.54);
    inch.set('yard', .0277778);
    inch.set('mile', .0000015783);
    this.convertMap.set('inch', inch);

    var foot = new Map();
    foot.set('meter', .03048);
    foot.set('inch', 12);
    foot.set('foot', 1);
    foot.set('kilometer', .0003048);
    foot.set('millimeter', 304.8);
    foot.set('centimeter', 30.48);
    foot.set('yard', 0.3333);
    this.convertMap.set('foot', foot);

    var kilometer = new Map();
    kilometer.set('meter', 1);
    kilometer.set('inch', 1);
    kilometer.set('foot', 1);
    kilometer.set('kilometer', 1);
    kilometer.set('millimeter', 1);
    kilometer.set('centimeter', 1);
    kilometer.set('yard', 1);
    this.convertMap.set('kilometer', kilometer);

    var millimeter = new Map();
    millimeter.set('meter', 1000);
    millimeter.set('inch', 39370.1);
    millimeter.set('foot', 3280.84);
    millimeter.set('kilometer', 1);
    millimeter.set('millimeter', 1000000);
    millimeter.set('centimeter', 100000);
    millimeter.set('yard', 1093.61);
    this.convertMap.set('millimeter', millimeter);

    var centimeter = new Map();
    centimeter.set('meter', 0.01);
    centimeter.set('inch', 0.393701);
    centimeter.set('foot', 0.0328084);
    centimeter.set('kilometer', 0.000001);
    centimeter.set('millimeter', 10);
    centimeter.set('centimeter', 1);
    centimeter.set('yard', 0.0109361);
    this.convertMap.set('centimeter', centimeter);

    var yard = new Map();
    yard.set('meter', .9144);
    yard.set('inch', 36);
    yard.set('foot', 3);
    yard.set('kilometer', .0009144);
    yard.set('millimeter', 914.4);
    yard.set('centimeter', 91.44);
    yard.set('yard', 914.4);
    this.convertMap.set('yard', yard);

  }
  ngOnInit() {
    this.configureConversionMap();
    this.convertForm = this.fb.group({
      convertFrom: [''],
      convertTo: [''],
      convertInput: [0]
    });
  }

}
