import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.css", "../../../app.component.css"],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @Output() menuEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
   
  ) {}

  deactivateAccount(): void {
    this.menuEvent.emit("deactivate");
  }
  openLoginDialog(): void {
    this.menuEvent.emit("login");
  }
  logOut(): void {
    this.menuEvent.emit("logout");
  }
  toggleDialog(): void {
    this.menuEvent.emit("toogle");
  }

  ngOnInit(): void {
   
  }

  ngOnDestroy(): void {
  }
}
