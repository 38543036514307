import { Component, OnInit } from '@angular/core';
import { WeatherAPIService } from '../../services/weather-api.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { WeatherRest } from '../../classes/weather-rest';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
  
  startDate: string = '';
  endDate: string = '';
  urlEndPoint:string = '';
  weatherFrom: UntypedFormGroup;
  weatherRest: WeatherRest;
  constructor(private weatherService: WeatherAPIService,
    private fb: UntypedFormBuilder) {}

  getData(startDate: string, endDate: string){
    this.startDate = startDate;
    this.endDate = endDate;
    return this.weatherService.getData(this.startDate, this.endDate);
  }
 

  ngOnInit() {
    this.weatherRest = new WeatherRest();
  
  }

}
