<h1>Weather</h1>
<form>
    <div>
        <label>Start Date: </label>
        <input type="date" formControlName="startDateInput">
        <label>End Date: </label>
        <input type="date" formControlName="endDateInput">
    </div>
    <button class="btn-success">Submit</button>
</form>
