import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConversionsService {

  convertMap: Map<String, Map<String, Number>>
  constructor() { }

  fahrenheitToCelsius(input: number){
    var celsius = (5/9)*(input - 32)
    return celsius;  
  }
  celsiusToFahrenheit(input: number){
    var fahrenheit = (9/5)*(input + 32);
    return fahrenheit;
  }
  celsiusToKelvin(input: number){
    var kelvin = input + 274.13;
    return kelvin;
  }
  configTemperatureConversionMap(){
    this.convertMap = new Map();
    var fahr = new Map();
    fahr.set('fahrenheit', 1);
    fahr.set('celsius', 1);
    fahr.set('kelvin', 1);
    fahr.set('rankin', 1);
    this.convertMap.set('fahrenheit', fahr);

    var celsius = new Map();
    celsius.set('fahrenheit', 1);
    celsius.set('celsius', 1);
    celsius.set('kelvin', 1);
    celsius.set('rankin', 1);
    this.convertMap.set('celsius', celsius);

    return this.convertMap;

  }
  configPressureConversionMap(){
    this.convertMap = new Map();
    var pascal = new Map();
    pascal.set('pascal', 1);
    pascal.set('bar', 0.00001);
    pascal.set('psi', 0.000145);
    pascal.set('atm', 0.0000099);
    pascal.set('kPa', 0.001);
    pascal.set('torr', 7520);
    this.convertMap.set('pascal', pascal);
    
    var bar = new Map();
    bar.set('pascal', 100000);
    bar.set('bar', 1);
    bar.set('psi', 14.5);
    bar.set('atm', 0.987);
    bar.set('kPa', 100);
    bar.set('torr', 751.88)
    this.convertMap.set('bar', bar);

    var psi = new Map();
    psi.set('pascal', 6895);
    psi.set('bar', 0.06895);
    psi.set('psi', 1);
    psi.set('atm', 0.06805);
    psi.set('kPa', 6.895);
    psi.set('torr', 51.813);
    this.convertMap.set('psi', psi);

    var atm = new Map();
    atm.set('pascal', 101330);
    atm.set('bar', 1.013);
    atm.set('psi', 14.7);
    atm.set('atm', 1);
    atm.set('kPa', 101.325);
    atm.set('torr', 760);
    this.convertMap.set('atm', atm);

    var kpa = new Map();
    kpa.set('pascal', 1000);
    kpa.set('bar', 0.01);
    kpa.set('psi', 0.145);
    kpa.set('atm', 0.00987);
    kpa.set('kPa', 1);
    kpa.set('torr', 7.52);
    this.convertMap.set('kpa', kpa);

    var torr = new Map();
    torr.set('pascal', 133);
    torr.set('bar', 0.00133);
    torr.set('psi', 0.0193);
    torr.set('atm', 0.00132);
    torr.set('kPa', 0.133);
    torr.set('tor', 1);
    this.convertMap.set('torr', torr);
    return this.convertMap;
  }
}
