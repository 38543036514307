import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// adsense
import { AdsenseModule } from 'ng2-adsense';


// app components
import { MainMenuComponent } from './components/menu/main-menu/main-menu.component';
import { SideMenuComponent } from './components/menu/side-menu/side-menu.component';
import { PressureComponent } from './components/conversions/pressure/pressure.component';
import { TemperatureComponent } from './components/conversions/temperature/temperature.component';
import { ConversionsService } from './services/conversions.service';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { LengthComponent } from './components/conversions/length/length.component';
import { CoronaAPIService } from './services/corona-api.service';
import { CoronaComponent} from './components/corona/corona.component';
import { StockAPIService } from './services/stock-api.service';
import { StockComponent } from './components/stock/stock.component';
import { WeatherAPIService } from './services/weather-api.service';
import { WeatherComponent } from './components/weather/weather.component';

//materials library
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";
import { MatRadioModule } from "@angular/material/radio";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTreeModule } from "@angular/material/tree";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatExpansionModule } from "@angular/material/expansion";
import { ProjectsComponent } from './components/projects/projects.component';

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    SideMenuComponent,
    PressureComponent,
    TemperatureComponent,
    HomeComponent,
    AboutComponent,
    LengthComponent,
    WeatherComponent,
    StockComponent,
    CoronaComponent,
    ProjectsComponent
  ],
  imports: [
    AdsenseModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatExpansionModule,
    MatTableModule,
    MatDialogModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
    MatListModule,
    MatTreeModule,
    MatTabsModule,
    MatPaginatorModule,
  ],
  providers: [ConversionsService, CoronaAPIService, StockAPIService, WeatherAPIService],
  bootstrap: [AppComponent]
})
export class AppModule { }
