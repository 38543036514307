import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AVsNotes';

  @ViewChild("sideMenu") sideNav: MatSidenav;
  checkoutCount: number;
  showSearch: boolean = false;
  search: string;

  addClient: string = environment.addClient;

  constructor(private router: Router) { }

  searchProduct(){

    this.router.navigate(['/search'], {queryParams: {q: this.search}})
    .then(() =>{
      this.toggleSearch();
    });
  }
 
  toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  incomingMenuEvent(task: Event) {

    
    
    this.sideNav.toggle();
  }


  incomingMainMenuEvent(task: string) {
   
    
    switch (task) {
      case "search":
        this.toggleSearch();
        break;
    }
    
    
    //this.sideNav.toggle();
        
  }

  toggleMenu() {
    this.sideNav.toggle();
  }
}
