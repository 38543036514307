<h1>Temperature Conversions</h1>

<form [formGroup]="convertForm">
    <div>
        <label> Convert From: </label>
        <select formControlName="convertFrom">
            <option *ngFor="let unit of units"
            [value]="unit">{{unit}}</option>
        </select>
        
        <label> Quantity: </label>
        <input type="number" formControlName="convertInput">

        <label> Convert To: </label>
        <select formControlName="convertTo">
            <option *ngFor="let unit of units"
                [value]="unit">{{unit}}</option>
        </select>
    </div>
        <button class="btn tbn-success" (click)=convertUnits(convertForm.value)>Convert</button>

</form>
<p>Output: {{quantity}} {{convertFrom}} =  {{convertOutput}} {{convertTo}}</p>
<h2>Units of Measure</h2>
<div class="description">
    <p> something something</p>
    <p> something something.</p>
</div>
<div class="units">
    <h3>Conversion Factor</h3>
</div>