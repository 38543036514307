
<h1>Pressure Conversions</h1>

<form [formGroup]="convertForm">
    <div>
        <label>Convert From: </label>
    
        <select formControlName="convertFrom">
            <option *ngFor="let unit of units"
                 [value]="unit">{{unit}}</option>
        </select>
       
        <label> Quantity: </label>
        <input type="number" formControlName="convertInput">
    
        <label> Convert To: </label>

        <select formControlName="convertTo">
            <option *ngFor="let unit of units" 
                 [value]="unit">{{unit}}</option>
        </select>
    </div>  
        <button class="btn btn-success" (click)="convertUnits(convertForm.value)">Convert</button>
    
</form>
<p>Output: {{quantity}} {{convertFrom}} = {{convertOutput}} {{convertTo}}</p>
<h2>Units of Measure</h2>
<div class="description">
    <p> Pressure in simple terms is the force applied over an area. This can be mathematically described as P=F/A</p>
    <p>where P is the Pressure F the force applied normal to area A.</p>
</div>
<div class="units">
    <h3>Conversion Factor</h3>
   <table>
       <thead>
           <tr>
               <th class="col"> </th>
               <th class="col">Pascal</th>
               <th class="col">Bar</th>
               <th class="col">Psi</th>
               <th class="col">Atm</th>
               <th class="col">kPa</th>
               <th class="col">Torr</th>
           </tr>
       </thead>
       <tbody>
           <tr>
               <th>Pascal</th>
               <td>1</td>
               <td>0.00001</td>
               <td>0.000145</td>
               <td>0.0000099</td>
               <td>0.001</td>
               <td>7520</td>
           </tr>

           <tr>
               <th>Bar</th>
               <td>100000</td>
               <td>1</td>
               <td>14.5</td>
               <td>0.987</td>
               <td>100</td>
               <td>751.88</td>
           </tr>

           <tr>
               <th>Psi</th>
               <td>6895</td>
               <td>0.06895</td>
               <td>1</td>
               <td>0.06805</td>
               <td>6.895</td>
               <td>51.813</td>
           </tr>

           <tr>
               <th>Atm</th>
               <td>101330</td>
               <td>1.013</td>
               <td>14.7</td>
               <td>1</td>
               <td>101.325</td>
               <td>760</td>
           </tr>

           <tr>
               <th>kPa</th>
               <td>1000</td>
               <td>0.01</td>
               <td>0.145</td>
               <td>0.00987</td>
               <td>1</td>
               <td>7.52</td>
           </tr>

           <tr>
               <th>Torr</th>
               <td>133</td>
               <td>0.00133</td>
               <td>0.0193</td>
               <td>0.00132</td>
               <td>0.133</td>
               <td>1</td>
           </tr>
       </tbody>
   </table>
</div>