// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDBcHK5NGmyi2WA1A9iMy6NvOn364ZrCtA",
    authDomain: "av-s-notes.firebaseapp.com",
    databaseURL: "https://av-s-notes.firebaseio.com",
    projectId: "av-s-notes",
    storageBucket: "av-s-notes.appspot.com",
    messagingSenderId: "873577328461",
    appId: "1:873577328461:web:95f2cc58b22d3c005b9104",
    measurementId: "G-JC19NCYM63"
  },
  addClient:'ca-pub-3843031759189649',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
