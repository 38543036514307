import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

@Component({
  selector: 'temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.css']
})
export class TemperatureComponent implements OnInit {
  convertForm: UntypedFormGroup;
  convertMap: Map<String, Map<String,Number>>;
  convertFrom = "";
  convertTo = "";
  quantity = "";
  convertOutput: number = 0;
  units = ['fahrenheit', 'celsius', 'kelvin', 'rankine'];

  constructor(private fb: UntypedFormBuilder) { }

  convertUnits(formInput){
    this.convertOutput = this.configureFormInputs(formInput.convertInput, formInput.convertFrom, formInput.convertTo);
    
    this.convertFrom = formInput.convertFrom;
    this.convertTo = formInput.convertTo;
    this.quantity = formInput.convertInput;
    console.log("convert To: " + formInput.convertTo);
    console.log("convert From: " + formInput.convertFrom);
    console.log("Input Arg: " + formInput.convertInput);
    console.log("Output: " + this.convertOutput);

  }
  configureFormInputs(input: number, convertFromArg: string, convertToArg: string){
    var result;
    switch(convertFromArg){
      case "fahrenheit":
        result = this.configureFahrenheit(input, convertToArg);
        console.log("picked fahr: ");
        break;
      case "celsius":
        result = this.configureCelsius(input, convertToArg);
        console.log("celsius picked");
        break;
      case "kelvin":
        result = this.configureKelvin(input, convertToArg);
        console.log("kelvin picked");
        break;
      case "rankine":
        result = this.configureRankine(input, convertToArg);
        console.log("rankin picked");

    }
     console.log("result: " + result);
    return result;
   
  }

  configureFahrenheit(input: number, convertToArg: string){
    var result;
    switch(convertToArg){
      case "celsius":
        result = this.fahrenheitToCelsius(input);
        break;
      case "rankine":
        result = this.fahrenheitToRankine(input);
        break;
      case "kelvin":
        result = this.fahrenheitToKelvin(input);
        break;
      default:
        result = input;
    }
    return result;
  }

  configureCelsius(input: number, convertToArg: string){
    var result;
    switch(convertToArg){
      case "fahrenheit":
        result = this.celsiusToFahrenheit(input);
        break;
      case "kelvin":
        result = this.celsiusToKelvin(input);
        break;
      case "rankine":
        result = this.celsiusToRankine(input);
        break;
      default:
        result = input;

    }
    return result;
  }

  configureKelvin(input: number, convertToArg: string){
    var result;
    switch(convertToArg){
      case "fahrenheit":
        result = this.kelvinToFahrenheit(input);
        break;
      case "celsius":
        result = this.kelvinToCelsius(input);
        break;
      case "rankine":
        result = this.kelvinToRankine(input);
        break;
      default:
          result = input;
        }
        return result;
  }

  configureRankine(input: number, convertToArg: string){
    var result;
    switch(convertToArg){
      case "fahrenheit":
        result = this.rankinToFahrenheit(input);
        break;
      case "celsius":
        result = this.rankinToCelsius(input);
        break;
      case "kelvin":
        result = this.rankinToKelvin(input);
        break;
      default:
        result = input;
    }
    return result;
  }

  fahrenheitToCelsius(input: number){
    var celsius = (5/9)*(input - 32);
    return celsius;  
  }
  
  fahrenheitToRankine(input: number){
    var rankin = input + 459.67;
    return rankin;
  }

  fahrenheitToKelvin(input: number){
    var kelvin = this.fahrenheitToCelsius(input) + 273.15;
    return kelvin;
  }

  celsiusToFahrenheit(input: number){
    var fahrenheit = (9/5)*(input) + 32;
    return fahrenheit;
  }

  celsiusToKelvin(input: number){
    var kelvin = input + 273.15;
    return kelvin;
  }

  celsiusToRankine(input: number){
    var rankine = this.celsiusToFahrenheit(input) + 459.67;
    return rankine;
  }

  kelvinToCelsius(input: number){
    var celsius = input - 273.15;
    return celsius;
  }
  
  kelvinToFahrenheit(input: number){
    var celsius = this.kelvinToCelsius(input);
    var fahrenheit = this.celsiusToFahrenheit(celsius);
    return fahrenheit;

  }

  kelvinToRankine(input: number){
    var fahrenheit = this.kelvinToFahrenheit(input) + 459.67;
    return fahrenheit;
  }

  rankinToFahrenheit(input: number){
    var fahrenheit = input - 459.67;
    return fahrenheit;
  }

  rankinToCelsius(input: number){
    var fahrenheit = this.rankinToFahrenheit(input);
    var celsius = this.fahrenheitToCelsius(fahrenheit);
    return celsius;
  }

  rankinToKelvin(input: number){
    var kelvin = this.rankinToCelsius(input) + 273.15;
    return kelvin;
  }


 
  ngOnInit() {
    this.convertForm = this.fb.group({
      convertFrom: [''],
      convertTo: [''],
      convertInput: [0]
    });
  }

}
