import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";


@Component({
  selector: "main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.css", "../../../app.component.css"],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  @Input() checkoutCount: number;
  @Output() mainMenuEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
  ) {}

  toggleSearch() {
    this.mainMenuEvent.emit("search");
  }
  openLoginDialog() {
    this.mainMenuEvent.emit("login");
  }
  logOut() {
    this.mainMenuEvent.emit("logout");
  }
  toggleMenu() {
    this.mainMenuEvent.emit("toogle");
  }
  ngOnInit(): void {
   
  }
  ngOnDestroy(): void {
  }
}
