import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class WeatherAPIService {


  constructor(private client: HttpClient) { }
  
  public getData(startDate: string, endDate: string){

    return this.client.get(this.setUrl(startDate, endDate));
  }
  private setUrl(startDate: string, endDate: string){

    var url: string = 'https://www.ncei.noaa.gov/access/services/data/v1?'
    + 'dataset=local-climatological-data&'
    + 'dataTypes='
    + 'HourlyStationPressure,'
    + 'HourlyDryBulbTemperature,'
    + 'HourlyRelativeHumidity,'
    + 'HourlyDewPointTemperature&'
    + 'stations=72278023183&'
    + 'startDate=' + startDate +'&'
    + 'endDate='+ endDate +'&'
    + 'includeAttributes=true&'
    + 'format=json';
    return url;
  }
}
