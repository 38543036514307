<mat-toolbar color="primary" style="height: 75px">
 
  <button mat-icon-button (click)="toggleMenu()" class="centered-icon-button">
    <img
      src="assets/logo.png"
        alt="logo"
        class="centered-image"
    />
  </button>
  
  <span class="app-title">Av's Notes</span>
   
  <section class="button-container" routerLink="/">
    <button mat-icon-button>
      <mat-icon>home</mat-icon>
    </button>
    <div class="button-text">Home</div>
  </section>
  <section class="button-container">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>route</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/projects']" [queryParams]="{ project: 'weather'}"><mat-icon>sunny</mat-icon>Weather</button>
      <button mat-menu-item [routerLink]="['/projects']" [queryParams]="{ project: 'covid-tracker'}"><mat-icon>coronavirus</mat-icon>Covid Tracker</button>
      <button mat-menu-item [routerLink]="['/projects']" [queryParams]="{ project: 'price-matcher'}"><mat-icon>request_quote</mat-icon>Price Matcher</button>
    </mat-menu>

    <div class="button-text">Projects</div>
  </section>
  <section class="menu-btn-spacer"></section>
</mat-toolbar>
